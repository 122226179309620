import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import * as _ from 'lodash'
import * as __ from '../utils';

export const CareersPageTemplate = ({title, openings, companySection, contentComponent}) => {
    const PageContent = contentComponent || Content
    return (
        <React.Fragment>
            <section className="section section--gradient mid-green-bg">
                <div className="container">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="has-text-centered">
                                <h2 className="title is-size-3 is-ligna-font font-white is-uppercase">
                                    /&nbsp;{title}&nbsp;/
                                </h2>
                                {/*<PageContent className="content" content={content} />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
              className={"section border-top-primary light-green-bg"}>
                <div className="container">
                    <div className={'columns'}>
                        <div className={'column is-12'}>
                            <div className={'card'}>
                                <div className={'card-header font-primary is-ligna-font p-0'}>
                                    <h3>{companySection.title}</h3>
                                </div>
                                <div className={'column is-3 no-padding'}>
                                    <hr/>
                                </div>
                                <p className={'markdown card-first-para'}>{__.parse(companySection.firstPara)}</p>
                                <p className={'markdown section-body'}>{__.parse(companySection.text)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {_.map(openings, (section, index) => {
                let hasLightBg = index % 2 !== 0;
                return <section
                    className={"section border-top-primary" + (hasLightBg ? ' light-green-bg' : ' mid-green-bg')}>
                    <div className="container">
                        <div className={'columns'}>
                            <div className={'column is-12'}>
                                {index === 0 && <h3 className={'is-ligna-font font-white mb-3 is-inline-flex is-align-items-center'}>Current Openings <span className={'count-tag'}>{openings.length}</span></h3>}
                                <div className={'relative'}>
                                <div className={'card'}>
                                    <div className={'card-header font-primary is-ligna-font p-0'}>
                                        <h3>{section.title}</h3>
                                        <h4>{section.tagline}</h4>
                                    </div>
                                    <div className={'column is-3 no-padding'}>
                                        <hr/>
                                    </div>
                                    <p className={'markdown card-first-para'}>{__.parse(section.firstPara)}</p>
                                    <article className={'markdown section-body'}>{__.parse(section.text)}</article>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>;
            })}
            {!openings.length && <section
              className={"section border-top-primary light-green-bg"}>
                <div className="container">
                    <div className={'columns'}>
                        <div className={'column is-12'}>
                            <h3 className={'is-ligna-font font-white mb-3 text-center'}>No current roles</h3>
                        </div>
                    </div>
                </div>
            </section>}
        </React.Fragment>
    )
}

CareersPageTemplate.propTypes = {
    // title: PropTypes.string.isRequired,
    // content: PropTypes.string,
    // contentComponent: PropTypes.func,
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

const CareersPage = ({data}) => {
    // const {markdownRemark: post} = data
    const {frontmatter} = data.markdownRemark
    console.log(frontmatter);

    return (
        <Layout>
            <CareersPageTemplate
                contentComponent={HTMLContent}
                title={frontmatter.title}
                openings={frontmatter.openings}
                companySection={frontmatter.companySection}
            />
        </Layout>
    )
}

CareersPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default CareersPage

export const careersPageQuery = graphql`
  query CareersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        companySection {
            title
            firstPara
            text
        }
        openings {
            title
            firstPara
            text
        }
      }
    }
  }
`
